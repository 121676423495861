import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';

function App() {

  const [responseDB, setresponseDB] = useState(null);
  const [responseOb, setresponseOb] = useState(null);

  const showList = async () => {
    const res = await fetch("https://spica-dev-dbapi-6i7xvrqwxq-an.a.run.app/");
    const data = await res.json();
    setresponseDB(data);
  };

  const showPicture = async () => {
    const res = await fetch("https://spica-dev-r2api-6i7xvrqwxq-an.a.run.app/");
    const data = await res.json();
    setresponseOb(data);
  };

  const clearData = () => {
    setresponseDB(null);
    setresponseOb(null);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <div>
          <button onClick={showList}>一覧表示</button>
          <button onClick={showPicture}>画像表示</button>
          <button onClick={clearData}>初期化</button>
        </div>
        {responseDB && (
          <div>
            <h3>取得したデータ:</h3>
            <pre>{JSON.stringify(responseDB, null, 2)}</pre>
          </div>
        )}
        {responseOb && (
          <div>
            <h3>取得したデータ:</h3>
            <img src = {responseOb} alt="" width= "90%" />
          </div>
        )}
      </header>
    </div>
  );
}

export default App;